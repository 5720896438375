var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "wrapper__card" }, [
      _c("div", { staticClass: "wrapper__card__header" }, [
        _vm._m(0),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            [
              _c("z-btn", {
                attrs: {
                  small: "",
                  rounded: "",
                  text: "voltar",
                  icon: "$keyboard_return_rounded",
                },
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "wrapper__card__body" }, [
        _c(
          "div",
          { staticClass: "wrapper__card__body__content" },
          [
            _c(
              "div",
              { staticClass: "wrapper__card__body__content__left" },
              [
                _c("z-input", {
                  staticClass: "mb-3",
                  attrs: {
                    label: "Nome da configuração",
                    type: "text",
                    hideDetails: true,
                  },
                  model: {
                    value: _vm.choosenName,
                    callback: function ($$v) {
                      _vm.choosenName = $$v
                    },
                    expression: "choosenName",
                  },
                }),
                _c("z-select", {
                  attrs: {
                    label: "Selecione uma conexão",
                    items: _vm.dbConfigList,
                    itemText: "name",
                    placeholder: "Pesquisar",
                    type: "text",
                    hideDetails: true,
                    returnObject: "",
                  },
                  model: {
                    value: _vm.choosenConnection,
                    callback: function ($$v) {
                      _vm.choosenConnection = $$v
                    },
                    expression: "choosenConnection",
                  },
                }),
                _vm.dbConfigTransformedList.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "wrapper__card__body__content__left__columns",
                      },
                      [
                        _c("span", { staticClass: "title__items" }, [
                          _vm._v("Conexões"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "wrapper__card__body__content__left__columns__list",
                          },
                          _vm._l(
                            _vm.dbConfigTransformedList,
                            function (item, i) {
                              return _c("ColumnItem", {
                                key: i,
                                staticClass: "item",
                                attrs: { info: item },
                                on: { startDraggin: _vm.startDrag },
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("v-divider", { staticClass: "hide", attrs: { vertical: "" } }),
            _c(
              "div",
              { staticClass: "wrapper__card__body__content__right" },
              [
                _c("TreeList", {
                  attrs: {
                    info: _vm.elementsData,
                    "send-data-prop": _vm.getDatasInfo,
                  },
                  on: { formContent: _vm.submitFormQuery },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "wrapper__card--actions" },
        [
          _c("z-btn", {
            attrs: {
              small: "",
              rounded: "",
              primary: "",
              text: "Adicionar",
              "is-loading": _vm.loadingAddAudience,
            },
            on: { click: _vm.addData },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v(" Filtros ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }